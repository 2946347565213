import clsx from "clsx";

type FormErrorProps = {
    children: string | null | undefined
}

export default function FormError({ children }: FormErrorProps) {

    if (!children || isBlank(children)) {
        return <></>
    }

    return <div id="error-message"
        className={clsx("whitespace-pre-line text-center text-sm font-semibold",
            "text-red-500 py-2 border-red-600 bg-red-100 rounded-md")}>
        {children}
    </div>
}

function isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
}