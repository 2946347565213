export type EndpointHandler<TResult> = (urlParams: object, bodyParams: object) => Promise<TResult>;

export type CallResultResponse<TResult> = { text: string, json: TResult; };
export type CallResult<TResult> = { status: number, response: CallResultResponse<TResult>; };

export type ControllerDefinition<TControllerEndpoints> = {
    [index in keyof TControllerEndpoints]: TControllerEndpoints[index] extends (basePath: string) => infer Return ? Return : void
};

export async function postJsonAsync(endpoint: string, payload: any) {
    const rawResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });

    await checkResponseStatus(rawResponse);

    const txt = await rawResponse.text();

    try {
        return JSON.parse(txt);
    } catch (e) {
        return null;
    }
}

async function checkResponseStatus(rawResponse: Response) {
    if (rawResponse.status === 401 || rawResponse.status == 403) {
        throw new Error("Unauthorised");
    } else if (rawResponse.status === 400) {

        var message = "";
        try {
            message = await rawResponse.text();

            if (!message || message == "") {
                message = "Failed to process";
            }

        } catch {
            message = "Failed to process";
        }

        throw new Error(message);
    }
    else if (rawResponse.status !== 200 &&
        rawResponse.status !== 201 &&
        rawResponse.status !== 202 &&
        rawResponse.status !== 203
    ) {
        throw new Error("Failed to process");
    }
}

export async function putJsonAsync(endpoint: string, payload: any) {
    const rawResponse = await fetch(endpoint, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });

    await checkResponseStatus(rawResponse);

    const txt = await rawResponse.text();

    try {
        return JSON.parse(txt);
    } catch (e) {
        return null;
    }
}

export async function getJsonAsync(endpoint: string) {
    const rawResponse = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    await checkResponseStatus(rawResponse);

    return await rawResponse.json();
}

export async function deleteAsync(endpoint: string) {
    const rawResponse = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    const txt = await rawResponse.text();

    await checkResponseStatus(rawResponse);

    try {
        return JSON.parse(txt);
    } catch (e) {
        return null;
    }
}

export async function deleteJsonWithStatusAsync<TResult>(endpoint: string): Promise<CallResult<TResult>> {
    const rawResponse = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    var text: string;
    var json: any;

    try {
        text = await rawResponse.text();
    } catch (e) {
        text = "";
    }

    try {
        json = await rawResponse.json();
    } catch (e) {
        json = null;
    }

    return {
        status: rawResponse.status,
        response: {
            text: text,
            json: json
        }
    };
}