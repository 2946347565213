import { useCallback, useState } from "react";
import { EndpointHandler, deleteAsync, getJsonAsync, postJsonAsync, putJsonAsync } from "../utils/fetch";

type fetchType = "POST" | "PUT" | "DELETE" | "GET"

export function useApiFetch<TResult>(method: fetchType = "POST", path: string) {

    const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isPending, setIsPending] = useState(false);
    const [data, setData] = useState<TResult | null | undefined>(null);

    //const [handler] = useState<EndpointHandler<TResult> | null>(createControllerEndpointHandler<TResult>(path, method))

    const commit = useCallback(
        // Here you will access to the latest updated options. 
        async (query: object, body: object, onSuccess?: (data: TResult) => void, onError?: (errorMessage: string) => void) => {

            if (isPending) {
                return;
            }

            setIsPending(true);
            setErrorMessage(null);
            setIsError(false)

            var handler = createControllerEndpointHandler<TResult>(path, method)

            await handler(query, body)
                .then(response => {
                    setData(response);

                    onSuccess && onSuccess(response)
                    //resolve(response)
                })
                .catch((err) => {
                    setIsError(true)

                    setErrorMessage(err.message)

                    onError && onError(err.message ?? "")
                    //reject(err)
                }).finally(() => setIsPending(false))
        },
        [path, method, setIsPending, setIsError, setErrorMessage]
    );

    const setError = (error: string) => {
        setIsError(true);
        setErrorMessage(error)
    }

    return { data, isError, errorMessage, isPending, commit, setError }
}

function createControllerEndpointHandler<TResult>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE'): EndpointHandler<TResult> {
    return (urlParams: object, bodyParams: object) => {
        return new Promise((resolve, reject) => {
            if (method == 'GET') {
                getJsonAsync(replaceParams(path, urlParams)).then(val => resolve(val)).catch(reject);
            } else if (method == 'POST') {
                postJsonAsync(replaceParams(path, urlParams), bodyParams).then(val => resolve(val)).catch(reject);
            } else if (method == 'PUT') {
                putJsonAsync(replaceParams(path, urlParams), bodyParams).then(val => resolve(val)).catch(reject);
            } else if (method == 'DELETE') {
                deleteAsync(replaceParams(path, urlParams)).then(val => resolve(val)).catch(reject);
            }
        })
    };
}


function replaceParams(fullPath: string, urlParams: any) {
    var regExp = /\{([^\}]+)\}/g;
    var matches = fullPath.match(regExp);

    var usedParamsInPath = [];

    for (var i in matches) {
        //@ts-ignore
        var paramName = matches[i].substring(1, matches[i].length - 1);
        fullPath = fullPath.replace('{' + paramName + '}', urlParams[paramName] ? urlParams[paramName] : '');
        fullPath = fullPath.replace('//', '/');

        //if (urlParams[paramName]) usedParamsInPath.push(urlParams[paramName]);
        //@ts-ignore
        usedParamsInPath.push(paramName);
    }

    function serializeParam(key: string, paramValue: any) {
        if (Array.isArray(paramValue)) {
            var path = paramValue[0];
            for (var i = 1; i < paramValue.length; i++) {
                if (paramValue[i]) {
                    path += '&' + key + '=' + encodeURIComponent(paramValue[i]);
                }
            }
            return path;
        } else {
            return encodeURIComponent(paramValue);
        }
    }

    var questMark = false;
    for (var i in urlParams) {
        //@ts-ignore
        if (usedParamsInPath.indexOf(i) == -1) {
            if (typeof urlParams[i] !== 'undefined') {
                if (questMark == false) {
                    fullPath += '?';
                    questMark = true;
                } else {
                    fullPath += '&';
                }

                fullPath += i + '=' + serializeParam(i, urlParams[i]);
            }
        }
    }

    return fullPath;
}