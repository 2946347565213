import clsx from "clsx";
import React from "react";
import { InputHTMLAttributes } from "react"

type InputProps = {
    label?: string | undefined
    children?: React.ReactNode
} & InputHTMLAttributes<HTMLInputElement>

export default React.memo(FormInput);

function FormInput({ label, children, ...rest }: InputProps) {

    return <div className="h-20 transition duration-200 w-full relative">
        {
            label && <label className="mb-2 block text-xs font-medium text-gray-900">{label}</label>
        }
        <div className="flex rounded-sm border border-gray-300 disabled:select-non">
            <input {...rest} className={clsx("flex w-full",
                "disabled:text-opacity-50  bg-gray-50 p-2.5 text-gray-900 outline-1",
                "focus:border-gray-400 focus:ring-gray-500 disabled:bg-gray-100 disabled:text-gray-800",
                rest.className ?? "")} />
            {children}
        </div>
    </div>
}